/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetBundleQuery } from '../models/GetBundleQuery';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ObservationControllerService {

    /**
     * Returns observations matching the provided criteria (from/to/loinc). The maximum time span between the two dates is one year.
     * @param effectiveFrom
     * @param effectiveTo
     * @param loinc
     * @returns string OK
     * @throws ApiError
     */
    public static getObservations(
        effectiveFrom: string,
        effectiveTo: string,
        loinc: Array<string>,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/observations',
            query: {
                'effectiveFrom': effectiveFrom,
                'effectiveTo': effectiveTo,
                'loinc': loinc,
            },
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Returns bundle object by params
     * @param query
     * @param observationDate
     * @param category
     * @param source
     * @param labOrderId
     * @returns string OK
     * @throws ApiError
     */
    public static getBundleByParams(
        query: GetBundleQuery,
        observationDate?: string,
        category?: 'SOCIALHISTORY' | 'VITALSIGNS' | 'IMAGING' | 'LABORATORY' | 'PROCEDURE' | 'SURVEY' | 'EXAM' | 'THERAPY' | 'ACTIVITY' | 'NULL',
        source?: 'HEALTHIE_STAGING' | 'HEALTHIE_PRODUCTION' | 'INTERNAL_QUESTIONNAIRE' | 'MESSENGER' | 'PATIENT_UPLOAD' | 'PATIENT_DEVICE' | 'TRUEPILL' | 'TRUEPILL_DIAGNOSTICS' | 'FALSEPILL' | 'FALSEPILL_DIAGNOSTICS' | 'CARE_TEAM_UPLOAD' | 'BIOREFERENCE' | 'BIOREFERENCE_QA' | 'PMP_DEV' | 'PMP_QA' | 'PMP_PROD' | 'QUEST_DIAGNOSTICS' | 'LABCORP' | 'TASSO' | 'EVERLYWELL' | 'SMARTMETERRPM' | 'OTHER_TRUSTED_LAB' | 'DEMO' | 'CONNECTIVEHEALTH' | 'APPLE_HEALTH',
        labOrderId?: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/observations/bundles',
            query: {
                'observationDate': observationDate,
                'category': category,
                'source': source,
                'labOrderId': labOrderId,
                'query': query,
            },
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Returns list of bundle objects grouped by lab order or effective date
     * @param category
     * @returns string OK
     * @throws ApiError
     */
    public static getObservationBundles(
        category: Array<'SOCIALHISTORY' | 'VITALSIGNS' | 'IMAGING' | 'LABORATORY' | 'PROCEDURE' | 'SURVEY' | 'EXAM' | 'THERAPY' | 'ACTIVITY' | 'NULL'>,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/observations/bundles/list',
            query: {
                'category': category,
            },
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
