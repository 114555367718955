/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents a scheduling URL for a given appointment type.
 */
export type AppointmentSchedulingUrlResponse = {
    /**
     * The appointment type.
     */
    type: AppointmentSchedulingUrlResponse.type;
    /**
     * The scheduling URL.
     */
    schedulingUrl: string;
};

export namespace AppointmentSchedulingUrlResponse {

    /**
     * The appointment type.
     */
    export enum type {
        SYNC_VISIT = 'SYNC_VISIT',
    }


}

