/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DownloadResultsRequest = {
    clinicalReportId: string;
    providerOrderReferenceId: string;
    reportStatus: DownloadResultsRequest.reportStatus;
};

export namespace DownloadResultsRequest {

    export enum reportStatus {
        CORRECTED = 'CORRECTED',
        FINAL = 'FINAL',
        NOT_AVAILABLE = 'NOT_AVAILABLE',
        UNKNOWN = 'UNKNOWN',
    }


}

